import { useMutation } from '@tanstack/react-query';

import useSupabase from '../../supabase';

export function usePostLinkWallet() {
  const client = useSupabase();

  const mutationFn = async (data: {
    message: any;
    signature: string;
    hmac: string;
  }) => {
    const response = await client.functions.invoke('link-wallet', {
      method: 'POST',
      body: {
        message: data.message, // Message used in the useSignature
        signature: data.signature, // Response from useSignature
        hmac: data.hmac, // Sig from GET nonce request
      },
    });
    return response;
  };

  return useMutation({ mutationFn });
}
