import { SupabaseClient } from '@supabase/supabase-js';
import { useQuery } from '@tanstack/react-query';

import useSupabase from './supabase';

export async function getLinkWallet(
  client: SupabaseClient<any, 'public', any>,
) {
  const { data, error } = await client.functions.invoke('link-wallet', {
    method: 'GET',
  });
  if (error) throw new Error(error.message);
  return data;
}

export function useGetLinkWallet() {
  const client = useSupabase();
  const queryKey = ['get_link_wallet'];

  const queryFn = async () => {
    return getLinkWallet(client).then((result: any) => result);
  };

  return useQuery({ queryKey, queryFn });
}
